import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTableCounter, ValueWithLabel, paginationLabels } from '../../common/common-components';
import { NotificationsContext } from '../../common/Notifications';
import useRisks from '../reviews/react-query/useRisks';
import { toRiskTableItems, toResourceRevisionItem } from '../reviews/react-query/translation';
import { getResourceOperationDisplay } from '../reviews/resource-revisions/ResourceRevisionOperation';
import useResourceRevision from './react-query/useResourceRevision';
import ResourceRevisionDiff from '../resource-revisions/ResourceRevisionDiff';
import { ColumnDefinitions } from './change-details-risk-table';
import { useCollection } from '@awsui/collection-hooks';
import { RISK_UNIQUE_IDENTIFIER_FIELD } from '../reviews/table-definitions/risk-table';
import { severityComparator } from '../reviews/table-definitions/common';
import {
  Box,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  Header,
  Pagination,
  SpaceBetween,
  Table
} from '@amzn/awsui-components-react';

const PAGE_SIZE = 10;

const ChangeDetails = () => {
  const { t } = useTranslation();
  const { reviewId, resourceId } = useParams() as { reviewId: string; resourceId: string };
  const notificationsControls = useContext(NotificationsContext);

  // Fetch the data related to this resource's revision from ChangeGuardian
  const { data: resourceRevisionData } = useResourceRevision(reviewId, resourceId, (err) =>
    notificationsControls.addAWSErrorNotification(err, 'Failed to load resource revision details')
  );

  // Extract the ResourceRevisionStructure item from the resourceRevisionData
  const resourceRevision = toResourceRevisionItem(resourceRevisionData);

  const {
    data: riskData,
    hasNextPage: hasMoreRisks,
    isLoading: isLoadingRisks,
    fetchNextPage: fetchMoreRisks
  } = useRisks(
    reviewId,
    1000,
    resourceId,
    () => {
      hasMoreRisks !== false && fetchMoreRisks();
    },
    (err) => notificationsControls.addAWSErrorNotification(err, 'Failed to load risks')
  );

  const memoizedRiskItems = useMemo(() => {
    const riskItems = toRiskTableItems(riskData);
    return riskItems;
  }, [riskData]);

  const { items, collectionProps, paginationProps } = useCollection(memoizedRiskItems, {
    pagination: { pageSize: PAGE_SIZE },
    sorting: { defaultState: { sortingColumn: { sortingComparator: severityComparator }, isDescending: true } },
    selection: { keepSelection: true, trackBy: RISK_UNIQUE_IDENTIFIER_FIELD }
  });

  return (
    <>
      {resourceRevisionData && (
        <Box>
          <Box margin='s'>
            <BreadcrumbGroup
              items={[
                { text: `Review ${reviewId}`, href: `/reviews/${reviewId}/` },
                { text: `${resourceId}`, href: `${reviewId}/change/${resourceId}` }
              ]}
              data-testid='breadcrumbs'
              ariaLabel='Breadcrumbs'
            />
          </Box>
          <Box margin='s'>
            <Container header={<Header variant='h2'>{`${t('Change Details for')} ${resourceId}`}</Header>}>
              <ColumnLayout columns={3} borders='vertical' data-testid='resource-revision-details'>
                <ValueWithLabel label={t('Resource ID')}>{resourceRevision.resourceId}</ValueWithLabel>
                <ValueWithLabel label={t('Logical ID')}>{resourceRevision.logicalId ?? '-'}</ValueWithLabel>
                <ValueWithLabel label={t('Resource Type')}>{resourceRevision.resourceType}</ValueWithLabel>
                <ValueWithLabel label={t('Container')}>{resourceRevision.resourceContainerId ?? '-'}</ValueWithLabel>
                <ValueWithLabel label={t('Template Type')}>{resourceRevision.templateType}</ValueWithLabel>
                <ValueWithLabel label={t('Operation')}>{getResourceOperationDisplay(resourceRevision)}</ValueWithLabel>
              </ColumnLayout>
            </Container>
          </Box>
          <Box margin='s'>
            <Table
              {...collectionProps}
              resizableColumns
              columnDefinitions={ColumnDefinitions()}
              items={items}
              loadingText='Loading resources'
              empty={
                <Box margin={{ vertical: 's' }} textAlign='center' color='inherit'>
                  <b>{t('No Risks')}</b>
                </Box>
              }
              header={
                <SpaceBetween size='s' direction='vertical'>
                  <Box>
                    <Header variant='h2' counter={getTableCounter(0, items.length, hasMoreRisks)}>
                      {t('Risks')}
                    </Header>
                  </Box>
                </SpaceBetween>
              }
              pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} openEnd={hasMoreRisks} />}
              loading={isLoadingRisks}
            />
          </Box>
          <Box margin='s'>
            <Container data-testid='resource-revision-diff'>
              <ResourceRevisionDiff resourceId={resourceId} reviewId={reviewId}></ResourceRevisionDiff>
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChangeDetails;
