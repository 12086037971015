import React, { useContext, useEffect, useState } from 'react';
import {
  RiskColumnDefinitionData,
  isRiskAcknowledgementDisabled,
  severityComparator
} from '../table-definitions/common';
import { RiskCardTableProps, columnDefinitions } from '../table-definitions/risk-card-table';
import {
  TableEmptyState,
  TableNoMatchState,
  getFilterCounterText,
  onClearTableTextFilterClick,
  paginationLabels
} from '../../../common/common-components';
import { CollectionActions } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Pagination, Table, TableProps, TextFilter, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import { useParams } from 'react-router';
import { SplitPanelContext } from '../../layout-housing/SplitPanel';
import { PAGE_SIZE } from '../table-definitions/risk-cards';
import i18n from '../../../i18n';
import { CardRiskSelectionContext } from '../context/CardRiskSelectionContext';

const RiskCardTable = ({ riskItems, ruleId, isLoading }: RiskCardTableProps) => {
  const [tableSelectedItems, setTableSelectedItems] = useState<RiskColumnDefinitionData[]>();
  const [filterActions, setFilterActions] = useState<CollectionActions<RiskColumnDefinitionData>>();
  const { reviewId } = useParams();
  const splitPanelControls = useContext(SplitPanelContext);
  const cardSelectionControls = useContext(CardRiskSelectionContext);

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, filterProps } = useCollection(
    riskItems,
    {
      pagination: { pageSize: PAGE_SIZE },
      sorting: { defaultState: { sortingColumn: { sortingComparator: severityComparator }, isDescending: true } },
      selection: { keepSelection: true, trackBy: 'resourceId' },
      filtering: {
        empty: <TableEmptyState resourceName='Risks' />,
        noMatch: <TableNoMatchState onClearFilterClick={onClearTableTextFilterClick(filterActions)} />
      }
    }
  );

  const onTableSelectionChange = (detail: TableProps.SelectionChangeDetail<RiskColumnDefinitionData>) => {
    setTableSelectedItems(detail.selectedItems);
    const currentCardRisksSelection = cardSelectionControls.riskSelectedItems;
    const newCardRisksSelection = currentCardRisksSelection
      .filter((item) => item.ruleId !== ruleId)
      .concat(detail.selectedItems);
    cardSelectionControls.updateRiskSelectedItems(newCardRisksSelection);
  };

  useEffect(() => {
    const ruleSelectedItems = cardSelectionControls.riskSelectedItems.filter((item) => item.ruleId === ruleId);
    setTableSelectedItems(ruleSelectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardSelectionControls.riskSelectedItems]);

  useEffect(() => {
    setTableSelectedItems([]);
    setFilterActions(actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskItems]);

  return (
    <Table
      {...collectionProps}
      resizableColumns
      onSelectionChange={({
        detail
      }: NonCancelableCustomEvent<TableProps.SelectionChangeDetail<RiskColumnDefinitionData>>) => {
        onTableSelectionChange(detail);
      }}
      selectedItems={tableSelectedItems}
      columnDefinitions={columnDefinitions(String(reviewId), splitPanelControls)}
      isItemDisabled={isRiskAcknowledgementDisabled}
      items={items}
      selectionType='multi'
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder={i18n.t('Search')}
          countText={getFilterCounterText(filteredItemsCount || 0)}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} openEnd={isLoading} />}
      wrapLines={true}
    />
  );
};

export default RiskCardTable;
