import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnLayout, Container, Header, Box, Spinner, SpaceBetween } from '@amzn/awsui-components-react';

import useRuleDetails from './react-query/useRuleDetails';
import { useTranslation } from 'react-i18next';
import { NotificationsContext } from '../../common/Notifications';
import { MarkdownDisplay, ValueWithLabel } from '../../common/common-components';

const RuleDetails = () => {
  const { t } = useTranslation();
  const notificationsControls = useContext(NotificationsContext);

  const params = useParams();

  const ruleId = String(params.ruleId);

  const { isLoading, data, isError } = useRuleDetails(ruleId, (err) =>
    notificationsControls.addAWSErrorNotification(err)
  );

  useEffect(() => {
    return () => {
      notificationsControls.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner size='large' data-testid='ruleDetailSpinner' />;
  }

  const ruleDetails = data?.rule;

  return (
    <>
      {!isError && ruleDetails && (
        <Box margin='s'>
          <SpaceBetween size='s'>
            <Container header={<Header variant='h2'>{ruleDetails.name}</Header>}>
              <SpaceBetween size='s'>
                <ColumnLayout columns={2}>
                  <ValueWithLabel label={t('Rule ID')}>{ruleDetails.ruleId}</ValueWithLabel>
                  <ValueWithLabel label={t('Severity')}>{ruleDetails.severity}</ValueWithLabel>
                </ColumnLayout>
                <ValueWithLabel label={t('Description')}>{ruleDetails.description}</ValueWithLabel>
              </SpaceBetween>
            </Container>
            <Container>
              <ValueWithLabel label={t('Documentation')}>
                {<MarkdownDisplay content={ruleDetails.ruleDocumentation} />}
              </ValueWithLabel>
            </Container>
          </SpaceBetween>
        </Box>
      )}
    </>
  );
};

export default RuleDetails;
