import React from 'react';

import i18n from '../../i18n';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { generateLink, ValueWithLabel, generateOriginUrl } from '../../common/common-components';
import { ReviewStructure } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { getUTCDateString } from './table-definitions/common';

interface ReviewMetaDataProp {
  review: ReviewStructure;
}
const ReviewMetaData = ({ review }: ReviewMetaDataProp) => {
  const source = review.source?.changeContext?.after?.toString() || '-';
  const sourceUrl = review.source?.url;
  const origin = review.origins?.[0]?.id?.toString() || '-';
  const originType = review.origins?.[0]?.type;
  const sourceType = review.source?.changeContext?.type;
  const changeUrl = review.origins?.[0]?.diffUrl;
  return (
    <ColumnLayout columns={3} borders='vertical' data-testid='review-metadata-details'>
      <ValueWithLabel label={i18n.t('Source')}>
        {sourceUrl ? generateLink(sourceUrl, source, true) : source}
      </ValueWithLabel>
      <ValueWithLabel label={i18n.t('Origin')}>
        {originType ? generateOriginUrl(originType, origin) : origin}
      </ValueWithLabel>
      <ValueWithLabel label={i18n.t('Creation Time')}>{getUTCDateString(review.createdOn)}</ValueWithLabel>
      <ValueWithLabel label={i18n.t('Source Type')}>{sourceType ?? '-'}</ValueWithLabel>
      <ValueWithLabel label={i18n.t('Change URL')}>
        {changeUrl ? generateLink(changeUrl, changeUrl, true) : '-'}
      </ValueWithLabel>
    </ColumnLayout>
  );
};

export default ReviewMetaData;
