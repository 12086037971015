import i18n from '../../../i18n';
import { FilteringProperty } from '@awsui/collection-hooks/dist/cjs/interfaces';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { allFiltersSetToAll, allSelectOption, matchingRiskItem } from './filter-menu';
import {
  ResourceRevisionList,
  ResourceRevisionStructure
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { SplitPanelContextProps } from '../../layout-housing/SplitPanel';
import {
  RiskColumnDefinitionData,
  getDescriptionColumnDefinition,
  getExpandButtonColumnDefinition,
  getResourceIdColumnDefinition,
  getRuleColumnDefinition,
  getStatusColumnDefinition,
  getSeverityColumnDefinition
} from './common';

interface RiskTableProps {
  readonly tableItems: RiskColumnDefinitionData[];
  isLoadingRisks: boolean;
  isMutating: boolean;
  isFiltering: boolean;
  hasMoreRisks: boolean;
  totalRiskCount: number;
}

export const RISK_UNIQUE_IDENTIFIER_FIELD = 'riskId';

const columnDefinitions = (reviewId: string, splitPanelContext: SplitPanelContextProps) => [
  getExpandButtonColumnDefinition(reviewId, splitPanelContext),
  getResourceIdColumnDefinition(reviewId),
  getRuleColumnDefinition(),
  getSeverityColumnDefinition(),
  getStatusColumnDefinition(),
  getDescriptionColumnDefinition()
];

export const RISK_FILTERING_PROPERTIES: FilteringProperty[] = [
  {
    propertyLabel: i18n.t('Severity'),
    key: 'severity',
    groupValuesLabel: i18n.t('Severity values'),
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: i18n.t('Rule'),
    key: 'ruleId',
    groupValuesLabel: i18n.t('Rule values'),
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: i18n.t('Status'),
    key: 'status',
    groupValuesLabel: i18n.t('Status values'),
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: i18n.t('Description'),
    key: 'description',
    groupValuesLabel: i18n.t('Description values'),
    operators: [':', '!:', '=', '!=']
  }
];

const someRiskStatus = (items: readonly RiskColumnDefinitionData[], status: string) => {
  return items.some((item) => item.status === status);
};

const findResourceRevision = (
  item: RiskColumnDefinitionData,
  resourceRevisionsItems: ResourceRevisionList,
  resourceTypeFilter?: OptionDefinition
): ResourceRevisionStructure | undefined => {
  if (resourceTypeFilter?.value !== allSelectOption.value) {
    return resourceRevisionsItems.find((resourceRevision) => resourceRevision.resourceId === item.resourceId);
  }
  return undefined;
};

export const toFilteredRiskTableItems = (
  riskItems: RiskColumnDefinitionData[],
  resourceRevisionsItems: ResourceRevisionList,
  statusFilter: OptionDefinition,
  ruleFilter?: OptionDefinition,
  resourceTypeFilter?: OptionDefinition
) => {
  if (allFiltersSetToAll([statusFilter, ruleFilter, resourceTypeFilter])) {
    return riskItems;
  }
  const filteredItems = riskItems.filter((item) => {
    const resourceRevision = findResourceRevision(item, resourceRevisionsItems, resourceTypeFilter);
    return matchingRiskItem(statusFilter, item, ruleFilter, resourceTypeFilter, resourceRevision);
  });
  return filteredItems;
};

export { type RiskTableProps, columnDefinitions, someRiskStatus };
